<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content :page-title="pageTitle">
      <template #actions>
        <el-button type="primary" icon="fal fa-plus" @click="newVideo">
          Neues Video
        </el-button>
      </template>
      <el-tabs type="border-card">
        <el-tab-pane label="Videos anzeigen">
          <el-table v-loading="loading" :data="category.videos">
            <el-table-column
              prop="name"
              label="Name"
              sortable
            ></el-table-column>
            <el-table-column prop="thumbnail_url" label="Thumbnail" width="180">
              <template #default="scope">
                <img
                  v-if="scope.row.thumbnail_url"
                  :src="scope.row.thumbnail_url"
                  width="150"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="length"
              label="Länge"
              width="120"
            ></el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteVideo"
                  subject="Video"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Videos sortieren">
          <el-alert
            title="Hinweis"
            description="Die Playlists können per Drag & Drop sortiert werden."
            type="info"
            show-icon
            style="margin-bottom: 20px;"
          >
          </el-alert>
          <el-tree
            :props="treeProps"
            :data="category.videos"
            node-key="id"
            draggable
            :allow-drop="allowDrop"
            @node-drop="handleDrop"
          >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DeleteButton from "@/components/DeleteButton.js"
import VideosRepository from "@/repositories/videos_repository.js"
import VideoCategoriesRepository from "@/repositories/video_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Video Playlist"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: false,
      category: [],
      treeProps: {
        label: "name"
      },
      pageTitle: "Playlist"
    }
  },
  computed: {},
  async created() {
    this.loadCategory()
  },
  methods: {
    async loadCategory() {
      this.loading = true

      try {
        this.category = await VideoCategoriesRepository.get(
          this.$route.params.id
        )
        this.pageTitle = `»${this.category.name}« Playlist`
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    handleEdit(video) {
      this.$router.push({ name: "EditVideoPage", params: { id: video.id } })
    },
    async deleteVideo(video) {
      this.category.videos.splice(this.category.videos.indexOf(video), 1)
      await VideosRepository.destroy(video.id)
    },
    async handleDrop() {
      const video_ids = this.category.videos.map(video => video.id)
      VideosRepository.sort(video_ids)
    },
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner"
    },
    newVideo() {
      this.$router.push({
        name: "NewVideoPage",
        params: { video_category_id: this.category.id }
      })
    }
  }
}
</script>
